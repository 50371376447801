import styled from 'styled-components'

export const SplashScreenFlex = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 100;
`

export const SplashScreenFlexRow = styled.div`
  flex: auto;
`

export const SplashScreenWrapper = styled.div`
  color: rgba(255, 255, 255, 0.85);
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
`

export const SplashScreenPrograssBar = styled.div`
  color: #fff;
  max-width: 250px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  margin-top: 3.5rem;
  height: 2px;

  svg {
    stroke: #fff;
  }
`
