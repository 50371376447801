import React, { useEffect, useState, useRef } from 'react'
import Alert from '@zera-admin/alert'
import Button from '@zera-admin/button'

import { useFlagcardContext, Data } from 'app/contexts/flagcard'

import * as Styled from './FlagcardItem.styled'

const FlagcardItem: React.FunctionComponent<Data> = ({
  children,
  id,
  ...rest
}) => {
  const [countdown, setCountdown] = useState(5)
  const timerRef = useRef<NodeJS.Timer>()
  const [flagcard] = useFlagcardContext()

  const setTimer = () => {
    timerRef.current = setInterval(() => {
      setCountdown((countdown) => countdown - 1)
    }, 1000)
  }

  const clearTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }

  useEffect(() => {
    setTimer()

    return function cleanup() {
      clearTimer()
    }
  }, [])

  useEffect(() => {
    if (countdown === 0) {
      clearTimer()

      if (id) {
        flagcard.remove(id)
      }
    }
  }, [flagcard, countdown, id])

  return (
    <Styled.Root>
      <Styled.Capsule onMouseEnter={clearTimer} onMouseLeave={setTimer}>
        <Alert
          actions={
            <Button size="small" onClick={() => setCountdown(0)}>
              Kapat
            </Button>
          }
          {...rest}
        >
          <p>{children}</p>
        </Alert>
      </Styled.Capsule>
      <Styled.Countdown>{countdown} saniye sonra kapanacak</Styled.Countdown>
    </Styled.Root>
  )
}

export default FlagcardItem
