import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react'
import { uid } from '@zera-admin/utils'

import { Data, Dispatch, Props, State } from './types'
import reducer from './reducer'

const initialState: State = []

const initialValue: Props = {
  dispatchs: {
    add: () => null,
    remove: () => null,
  },
  provided: false,
  state: initialState,
}

export const FlagcardContext = createContext<Props>(initialValue)

export const FlagcardProvider: React.FunctionComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const add = useCallback(({ id, appearance, ...rest }: Data) => {
    dispatch({
      type: 'ADD_FLAGCARD',
      payload: {
        id: id || uid(),
        appearance: appearance || 'success',
        ...rest,
      },
    })
  }, [])

  const remove = useCallback((id: string) => {
    dispatch({
      type: 'REMOVE_FLAGCARD',
      payload: id,
    })
  }, [])

  const dispatchs = useMemo(
    () => ({
      add,
      remove,
    }),
    [add, remove]
  )

  return (
    <FlagcardContext.Provider
      value={{
        dispatchs,
        provided: true,
        state,
      }}
    >
      {children}
    </FlagcardContext.Provider>
  )
}

export const useFlagcardContext = (): [Dispatch, State] => {
  const { state, provided, dispatchs } = useContext(FlagcardContext)

  if (!provided) {
    throw new Error(
      'useFlagcardContext must be used within a FlagcardProvider.'
    )
  }

  return [dispatchs, state]
}
