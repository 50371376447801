import {
  AuthLoginRequest,
  AuthRefreshTokenRequest,
  AuthResponse,
  AuthTwoFactorRequest,
} from './types'

export const mapAuthForLoginRequest = (raw: AuthLoginRequest): any => ({
  email: raw.email,
  password: raw.password,
  appName: raw.appName,
})

export const mapAuthForTwoFactorRequest = (raw: AuthTwoFactorRequest): any => ({
  twoFactorNumber: raw.code,
  email: raw.email,
})

export const mapAuthForRefreshTokenRequest = (
  raw: AuthRefreshTokenRequest
): any => ({
  refreshToken: raw.refreshToken,
})

export const mapAuthResponse = (raw: any): AuthResponse => ({
  accessToken: raw.data.accessToken,
  refreshToken: raw.data.refreshToken,
  loginType: raw.data.loginType,
  email: raw.data.email,
})
