import { Query } from 'services/http/bi-tool/query'

import { SET_DASHBOARD_QUERY, SET_DASHBOARD_SCHEMA } from './constants'
import { Action } from './types'

export const set = (payload: unknown[]): Action => ({
  type: SET_DASHBOARD_SCHEMA,
  payload,
})

export const query = (payload: Query): Action => ({
  type: SET_DASHBOARD_QUERY,
  payload,
})
