import React, { useEffect, useState } from 'react'
import { storage } from '@zera-admin/helpers'

import authService from 'services/http/identity-server/auth'

import { Error } from 'app/shared/types'
import SplashScreen from 'components/splash-screen'

const Capsule: React.FunctionComponent = ({ children }) => {
  const [error] = useState<Error>()
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (storage.get('token')) {
      authService
        .validation()
        .then(() => {
          setIsReady(true)
        })
        .catch(() => {
          storage.remove('token')
          storage.remove('refreshToken')

          window.location.href = window.location.origin + '/signout'
        })
    } else {
      setIsReady(true)
    }
  }, [])

  if (isReady) {
    return (
      <React.Suspense fallback={<SplashScreen />}>{children}</React.Suspense>
    )
  }

  return <SplashScreen error={error} />
}

export default Capsule
