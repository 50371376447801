import { usePopupContext } from 'app/contexts/popup'

import PopupItem from './PopupItem'

const Popup: React.FunctionComponent = () => {
  const [, popups] = usePopupContext()

  if (popups.length) {
    return (
      <div>
        {popups.map((props) => (
          <PopupItem key={props.id} {...props} />
        ))}
      </div>
    )
  }

  return null
}

export default Popup
