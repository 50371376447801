import { Action, State } from './types'

import { SET_WITHBOTS } from './constants'

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_WITHBOTS:
      return {
        ...state,
        withBots: action.payload,
      }

    default:
      return state
  }
}

export default reducer
