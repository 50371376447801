import { SET_LANGUAGE } from './constants'
import { SystemAction, SystemState } from './types'

const initialState: SystemState = {
  language: 'en',
}

const systemReducer = (
  state = initialState,
  action: SystemAction
): SystemState => {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      }
    default:
      return state
  }
}

export default systemReducer
