import Modal from '@zera-admin/modal'

import { Data, usePopupContext } from 'app/contexts/popup'

const Popup: React.FunctionComponent<Data> = ({ children, id, ...rest }) => {
  const [popup] = usePopupContext()

  const handleClose = () => {
    if (id) {
      popup.remove(id)
    }
  }

  return (
    <Modal
      actions={[
        {
          text: 'Kapat',
          onClick: handleClose,
        },
      ]}
      width="small"
      onClose={handleClose}
      {...rest}
    >
      {children}
    </Modal>
  )
}

export default Popup
