import { useFlagcardContext } from 'app/contexts/flagcard'

import FlagcardsItem from './FlagcardItem'
import * as Styled from './Flagcard.styled'

const Flagcards: React.FunctionComponent = () => {
  const [, flagcards] = useFlagcardContext()

  if (flagcards.length) {
    return (
      <Styled.Root>
        {flagcards.map((props) => (
          <FlagcardsItem key={props.id} {...props} />
        ))}
      </Styled.Root>
    )
  }

  return null
}

export default Flagcards
