import React from 'react'
import { Helmet } from 'react-helmet'

import { colors } from '@atlaskit/theme'

import { ZeraIcon } from '@zera-admin/logo'
import Spinner from '@zera-admin/spinner'

import { SplashScreenProps } from './types'
import {
  SplashScreenFlex,
  SplashScreenFlexRow,
  SplashScreenWrapper,
  SplashScreenPrograssBar,
} from './SplashScreen.styled'

const SplashScreen: React.FunctionComponent<SplashScreenProps> = ({
  error,
}) => {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />
        <meta name="theme-color" content={colors.N400} />
        <style>{`
          body {
            background-color: ${colors.N400};
          }
        `}</style>
      </Helmet>
      <SplashScreenFlex>
        <SplashScreenFlexRow>
          <SplashScreenWrapper>
            <ZeraIcon size="large" />
            {error ? (
              <React.Fragment>
                <p>{error.message}</p>
                <p>
                  {error.code}: {error.name}
                </p>
              </React.Fragment>
            ) : (
              <SplashScreenPrograssBar>
                <Spinner />
              </SplashScreenPrograssBar>
            )}
          </SplashScreenWrapper>
        </SplashScreenFlexRow>
      </SplashScreenFlex>
    </React.Fragment>
  )
}

export default SplashScreen
