import { Action, State } from './types'

import { SET_DASHBOARD_QUERY, SET_DASHBOARD_SCHEMA } from './constants'

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case SET_DASHBOARD_SCHEMA:
      return { ...state, schema: action.payload }
    case SET_DASHBOARD_QUERY:
      return { ...state, query: action.payload }

    default:
      return state
  }
}

export default reducer
