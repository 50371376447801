import styled from 'styled-components'

export const Root = styled.div({
  width: '100%',
  animation: '350ms slidein cubic-bezier(0.15, 1, 0.3, 1)',

  ':not(:first-child)': {
    marginTop: 8,
  },

  '@keyframes slidein': {
    from: {
      opacity: '0.2',
      marginLeft: '50%',
    },
    to: {
      opacity: '1',
      marginLeft: '0%',
    },
  },
})

export const Capsule = styled.div`
  border-radius: 2;
  box-shadow: 'rgb(9 30 66 / 25%) 0px 0 8px';
`

export const Countdown = styled.div({
  fontSize: 11,
  marginTop: 4,
  textAlign: 'right',
  fontStyle: 'italic',
})
